export const path = {
  root: "/",
  actionTop: "/greenAction",
  actionDetail: (actionId: string) =>
    `/greenAction/detail?actionId=${actionId}`,
  accountRegist: "/account/regist",
  accountDelete: "/account/delete",
  tutorial: "/onboarding/",
  contactUs: "/contactUs",
  addHome: "/addHome",
  logout: "/logout",
  terms: "/contents/terms.html",
  faq: process.env["NEXT_PUBLIC_FAQ_URL"],
  privacyPolicy: "/contents/privacyPolicy.html",
  error: "/error",
  greenscore: {
    home: "/",
    initScore: "/initialScore",
    retroepective: "/retrospective",
    retrospectiveComplete: "/reviewComplete",
    actionTop: "/action/list",
    actionSelect: "/action/add",
    habitualSelect: "/action/habitual",
    actionDetail: "/action/addDetail",
    addActionDetail: (actionId: string) =>
      `/action/addActionDetail?actionId=${actionId}`,
    habitualActionDetail: (actionId: string) =>
      `/action/habitualActionDetail?actionId=${actionId}`,
    actionEdit: (actionId: string) => `/action/edit?actionId=${actionId}`,
  },
  initialScoreResult: "/initialScoreResult",
  // confirm: "/associationConfirm/confirm",
  electricity: "/electricity",
  startApplicationConsent: "/associationConfirm/start",
  inputApplicationConsent: "/associationConfirm/input",
  completeApplicationConsent: "/associationConfirm/complete",
  failApplicationConsent: "/associationConfirm/fail",
  infoGreenScorePage: "/infoGreenScorePage",
  aboutElectricity: "/aboutElectricity",
  profile: {
    index: "/profile",
    edit: "/profile/edit",
  },
  powerLinkageInfo: "/powerLinkageInfo/powerLinkageInfo",
  linkageStructurePage: "/linkageStructurePage",
  InfoDetailFormPage: "/InfoDetailFormPage",
  friendIntroductionPage: "/friendIntroduction",
};

const DOMAIN_COMMON = process.env.NEXT_PUBLIC_DURIAN_URL;
const WINDOW_NAME_COMMON = "durian";
let openedWindow: Window | null;
/**
 * 共通アプリを別タブで開く
 * @param path
 */
export const openCommonApp = (path: string): void => {
  if (typeof window !== "undefined") {
    if (openedWindow) {
      openedWindow.close();
    }
    openedWindow = window.open(`${DOMAIN_COMMON}${path}`, WINDOW_NAME_COMMON);
  }
};
