import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NaviType } from "../../components/atoms/GlobalNavi";

export type MenuActionState = {
  currentPage?: NaviType;
};

const initialState: MenuActionState = {
  currentPage: undefined,
};

/**
 * グローバルナビメニューのカレント表示を管理する
 */
const menuActionSlice = createSlice({
  name: "menuAction",
  initialState,
  reducers: {
    setCurrentPage: (state, action: PayloadAction<NaviType>) => ({
      ...state,
      currentPage: action.payload,
    }),
    initial: (state, action: PayloadAction<void>) => initialState,
  },
});

export default menuActionSlice.reducer;

export const { setCurrentPage, initial } = menuActionSlice.actions;
