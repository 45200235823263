// Or from '@reduxjs/toolkit/query/react'
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store";
import {
  ChangeHabitRequest,
  ReviewRequest,
  Payload,
  ConsentAppResponse,
  InitScoreInfo as InitScoreRequest,
  InitScoreClearInfo as InitScoreClearRquest,
  ConsentCancelHeaders,
} from "./types";

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env["NEXT_PUBLIC_GREENSCORE_BACKEND_URL"] as string,
    prepareHeaders: (headers, { getState }) => {
      headers.set("Content-Type", "application/json");
      const token = (getState() as RootState).auth0Action.accessToken;
      if (token) headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  reducerPath: "greenscore",
  endpoints: (builder) => ({
    reviewRequest: builder.mutation<void, ReviewRequest>({
      query: (review_id) => ({
        url: `greenscore/actionscore-by-review`,
        method: "POST",
        body: review_id,
      }),
    }),
    changeHabitApi: builder.mutation<void, ChangeHabitRequest>({
      query: (changeHabitRequest) => ({
        url: `greenscore/actionscore-by-change-habit`,
        method: "POST",
        body: changeHabitRequest,
      }),
    }),
    initScore: builder.mutation<{ result_score: number }, InitScoreRequest>({
      query: (initScoreInfo) => ({
        url: `greenscore/init-score`,
        method: "POST",
        body: initScoreInfo,
      }),
    }),
    initScoreClear: builder.mutation<void, InitScoreClearRquest>({
      query: (initScoreClearInfo) => ({
        url: `greenscore/init-score-clear`,
        method: "POST",
        body: initScoreClearInfo,
      }),
    }),
  }),
});

type ConsentAppRequest = Payload;
export const api2 = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env["NEXT_PUBLIC_DATALINK_BACKEND_URL"] as string,
    prepareHeaders: (headers, { getState }) => {
      // headers.set("Content-Type", "application/json");
      // headers.set("x-request-id", uuidv4());
      // // 前段のアクセストークン発行の値
      // // TODO セッションから取得でよいか？＆未存在時のエラー制御
      // // headers.set("x-session-id", "AAA");
      // const sessionId = window.localStorage.getItem("data-link-sessionid");
      // if (sessionId) {
      //   headers.set("x-session-id", sessionId);
      // }
      // const token = (getState() as RootState).auth0Action.accessToken;
      // if (token) headers.set("Authorization", `Bearer ${token}`);
      // return headers;
    },
  }),
  reducerPath: "consentApp",
  endpoints: (builder) => ({
    consentApp: builder.mutation<ConsentAppResponse, ConsentAppRequest>({
      query: (payload) => ({
        url: `consent-applications`,
        method: "POST",
        body: payload.payloadBody,
        headers: {
          "Content-Type": "application/json",
          "x-request-id": payload.consentAppHeaders.requestId,
          "x-session-id": payload.consentAppHeaders.sessionId,
          Authorization: `Bearer ${payload.consentAppHeaders.token}`,
        },
      }),
    }),
    getIdentificationUrl: builder.query<
      { status: number; result: { url: string } },
      string
    >({
      query: (id) => ({ url: `identification-url?id=${id}`, method: "GET" }),
    }),
  }),
});

export const api3 = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env["NEXT_PUBLIC_GREENSCORE_BACKEND_URL"] as string,
    prepareHeaders: (headers, { getState }) => {
      // headers.set("Content-Type", "application/json");
      // headers.set("x-request-id", uuidv4());
      // const token = (getState() as RootState).auth0Action.accessToken;
      // if (token) headers.set("Authorization", `Bearer ${token}`);
      // return headers;
    },
  }),
  reducerPath: "consentCancelApp",
  endpoints: (builder) => ({
    consentCancelApp: builder.mutation<void, ConsentCancelHeaders>({
      query: (header) => ({
        url: `consent-cancel-applications`,
        method: "POST",
        body: {},
        headers: {
          "Content-Type": "application/json",
          "x-request-id": header.requestId,
          Authorization: `Bearer ${header.token}`,
        },
      }),
    }),
  }),
});

// const sessionId = localStorage.getItem("sessionId");
export default api;
export const {
  useReviewRequestMutation,
  useChangeHabitApiMutation,
  useInitScoreMutation,
  useInitScoreClearMutation,
} = api;
export const { useConsentAppMutation, useGetIdentificationUrlQuery } = api2;
export const { useConsentCancelAppMutation } = api3;
