// Or from '@reduxjs/toolkit/query/react'
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GreenscoreActions } from "./types";

export const CMS_TAG_TYPE = {
  GREENSCORE_ACTIONS: "GreenscoreActions",
} as const;

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env["NEXT_PUBLIC_CMS_BASE_URL"] as string,
    prepareHeaders: (headers) => {
      headers.set(
        process.env["NEXT_PUBLIC_CMS_API_KEY_HEADER"] as string,
        process.env["NEXT_PUBLIC_CMS_API_KEY_VALUE"] as string
      );
      return headers;
    },
  }),
  reducerPath: "cmsGreenscoreAction",
  tagTypes: [CMS_TAG_TYPE.GREENSCORE_ACTIONS],
  endpoints: (builder) => ({
    getGreenscoreActions: builder.query<GreenscoreActions, void>({
      query: () => `greenscore-action?orders=category&limit=100`,
    }),
    getGreenscoreActionsByIds: builder.query<GreenscoreActions, string[]>({
      query: (actionIds: string[]) => {
        let params = "";
        actionIds.forEach((actionId, index) => {
          params += index !== 0 ? "," + actionId : actionId;
        });
        return `greenscore-action?ids=${params}`;
      },
    }),
  }),
});
export default api;
export const {
  useGetGreenscoreActionsQuery,
  useGetGreenscoreActionsByIdsQuery,
} = api;
