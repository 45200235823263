import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { api as graphql } from "./graphql";
import auth0BackendApi from "../services/auth0/";
import Auth0ActionReducer from "./auth0/slice";
import GreenscoreReducer from "./greenscore/slice";
import ErrorReducer from "./error/slice";
import MenuReducer from "./menu/slice";
import OnboardingReducer from "./onboarding/slice";
import ReferralCodeReducer from "./referralCode/slice";
import cmsGreenscoreActionApi from "../services/cms/greenscoreAction";
import cmsInitialScoreResultApi from "../services/cms/initialScoreResult";
import cmsMissionContentsApi from "../services/cms/missionContents";
import { api as aws, api3 as aws3 } from "../services/aws/index";

const store = configureStore({
  reducer: {
    auth0Action: Auth0ActionReducer,
    greenscoreAction: GreenscoreReducer,
    error: ErrorReducer,
    menuAction: MenuReducer,
    onboarding: OnboardingReducer,
    referralCode: ReferralCodeReducer,
    [graphql.reducerPath]: graphql.reducer,
    [auth0BackendApi.reducerPath]: auth0BackendApi.reducer,
    [cmsGreenscoreActionApi.reducerPath]: cmsGreenscoreActionApi.reducer,
    [cmsInitialScoreResultApi.reducerPath]: cmsInitialScoreResultApi.reducer,
    [cmsMissionContentsApi.reducerPath]: cmsMissionContentsApi.reducer,
    [aws.reducerPath]: aws.reducer,
    [aws3.reducerPath]: aws3.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(graphql.middleware)
      .concat(auth0BackendApi.middleware)
      .concat(cmsGreenscoreActionApi.middleware)
      .concat(cmsInitialScoreResultApi.middleware)
      .concat(cmsMissionContentsApi.middleware)
      .concat(aws.middleware)
      .concat(aws3.middleware),
  devTools: process.env.NODE_ENV !== "production",
  // preloadedState: preloadedState(),
});
export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
