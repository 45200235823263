import { css } from "@emotion/css";
import { FC, useCallback } from "react";

type Props = {
  title: string;
  onclick: Function;
  isDisabled: boolean;
  outlined?: boolean;
};

//
/**
 * アクション追加ボタン
 * isDisabledが「false」な限り、何度でも押下出来るボタン
 * TODO 汎用ボタンとするなら、命名要見直し
 *
 * @param param0
 * @returns
 */
export const ActionAddButton: FC<Props> = ({
  title = "",
  // TODO
  onclick,
  isDisabled,
  outlined,
}) => {
  // TODO
  const doneClick = useCallback(() => {
    if (!isDisabled) {
      onclick();
    }
  }, [isDisabled, onclick]);

  return (
    <div
      id="actionAdd-Button"
      className={
        isDisabled
          ? buttonStyleDisabled
          : outlined
          ? buttonStyleOutline
          : buttonStyle
      }
      onClick={() => doneClick()}
    >
      <span className={titleStyle}>{title}</span>
    </div>
  );
};

export const titleStyle = css`
  height: 24px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
`;

export const buttonStyleDisabled = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 283px;
  height: 44px;
  border-radius: 22px;
  background: #e1e1e1;
  cursor: default;
`;

export const buttonStyleOutline = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 283px;
  height: 44px;
  border-radius: 22px;
  border: 1px solid #fff;
  background: transparent;
  cursor: pointer;
`;

export const buttonStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 283px;
  height: 44px;
  border-radius: 22px;
  background: linear-gradient(90deg, #1e5343 0%, #359d88 100%);
  cursor: pointer;
`;
