// Or from '@reduxjs/toolkit/query/react'
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  MissionContentsList,
  MissionContents,
  ChallengeContentDetail,
} from "./types";

export const CMS_TAG_TYPE = {
  MISSION_CONTENTS_List: "MissionContentsList",
  MISSION_CONTENTS: "MissionContents",
  CHALLENGE_CONTENTS: "ChallengeContentDetail",
} as const;

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env["NEXT_PUBLIC_CMS_BASE_URL"] as string,
    prepareHeaders: (headers) => {
      headers.set(
        process.env["NEXT_PUBLIC_CMS_API_KEY_HEADER"] as string,
        process.env["NEXT_PUBLIC_CMS_API_KEY_VALUE"] as string
      );
      return headers;
    },
  }),
  reducerPath: "cmsMissionContents",
  tagTypes: [
    CMS_TAG_TYPE.MISSION_CONTENTS_List,
    CMS_TAG_TYPE.MISSION_CONTENTS,
    CMS_TAG_TYPE.CHALLENGE_CONTENTS,
  ],
  endpoints: (builder) => ({
    // ミッション一覧取得
    getMissionList: builder.query<MissionContentsList, void>({
      query: () => `mission?depth=0&limit=100`,
    }),
    // ミッション詳細取得
    getMissionDetail: builder.query<MissionContents, string>({
      query: (contentId: string) => `mission/${contentId}`,
    }),
    // チャレンジ詳細取得
    getChallengeDetail: builder.query<ChallengeContentDetail, string>({
      query: (contentId: string) => `challenge/${contentId}`,
    }),
    // ミッション経由でチャレンジを取得
    getMissionChallenge: builder.query<
      { contents: Pick<MissionContents, "companyName" | "challenge">[] },
      { missionId: string; challengeId: string }
    >({
      query: ({ missionId, challengeId }) =>
        `mission?fields=companyName,challenge&filters=id[equals]${missionId}[and]challenge[contains]${challengeId}`,
    }),
  }),
});
export default api;
export const {
  useGetMissionListQuery,
  useGetMissionDetailQuery,
  useGetChallengeDetailQuery,
  useGetMissionChallengeQuery,
} = api;
