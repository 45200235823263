import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ActionListType } from "../../pages/action/list";
import { GreenscoreActionCategory } from "../cms/greenscoreAction/types";

export type ActionCategory = GreenscoreActionCategory | "すべて";

export type ActionListState = {
  currentTab: ActionCategory;
  selectedActionIds: string[];
};

export type ActionTopState = {
  currentTab: ActionListType;
};

export type GreenscoreState = {
  actionList_add: ActionListState;
  actionList_habitual: ActionListState;
  actionTop: ActionTopState;
  isDoneActionReview: boolean;
  isDoneTopPage: boolean; // トップページ表示済／未
};

const categoryList = new Array<GreenscoreActionCategory>();
const initialState: GreenscoreState = {
  actionList_add: {
    currentTab: categoryList[0],
    selectedActionIds: [],
  },
  actionList_habitual: {
    currentTab: categoryList[0],
    selectedActionIds: [],
  },
  actionTop: {
    currentTab: "取組み中",
  },
  isDoneActionReview: false,
  isDoneTopPage: false,
};

const greenscoreSlice = createSlice({
  name: "greenscore",
  initialState,
  reducers: {
    setActionListCurrentTab: (state, action: PayloadAction<ActionCategory>) => {
      let addListTab = state.actionList_add.currentTab;
      let habitualListTab = state.actionList_habitual.currentTab;
      if (state.actionTop.currentTab == "取組み中") {
        addListTab = action.payload;
      } else {
        habitualListTab = action.payload;
      }
      return {
        ...state,
        actionList_add: {
          currentTab: addListTab,
          selectedActionIds: state.actionList_add.selectedActionIds,
        },
        actionList_habitual: {
          currentTab: habitualListTab,
          selectedActionIds: state.actionList_habitual.selectedActionIds,
        },
      };
    },
    toggleSelectedActionId: (state, action: PayloadAction<string>) => {
      const currentTab = state.actionTop.currentTab == "取組み中";
      let nextSelectedActionIds = Array.from(
        currentTab
          ? state.actionList_add.selectedActionIds
          : state.actionList_habitual.selectedActionIds
      );
      if (
        !nextSelectedActionIds.find((actionId) => actionId == action.payload)
      ) {
        // 対象IDが選択状態でない場合、追加
        nextSelectedActionIds.push(action.payload);
      } else {
        // 対象IDが選択状態の場合、除外
        nextSelectedActionIds = nextSelectedActionIds.filter(
          (actionId) => actionId !== action.payload
        );
      }
      let addListSelectedIds = state.actionList_add.selectedActionIds;
      let habitualListSelectedIds = state.actionList_habitual.selectedActionIds;
      if (currentTab) {
        addListSelectedIds = nextSelectedActionIds;
      } else {
        habitualListSelectedIds = nextSelectedActionIds;
      }
      return {
        ...state,
        actionList_add: {
          currentTab: state.actionList_add.currentTab,
          selectedActionIds: addListSelectedIds,
        },
        actionList_habitual: {
          currentTab: state.actionList_habitual.currentTab,
          selectedActionIds: habitualListSelectedIds,
        },
      };
    },
    resetSelectedActionIds: (state, action: PayloadAction<undefined>) => ({
      ...state,
      actionList_add: {
        currentTab: state.actionList_add.currentTab,
        selectedActionIds: initialState.actionList_add.selectedActionIds,
      },
      actionList_habitual: {
        currentTab: state.actionList_habitual.currentTab,
        selectedActionIds: initialState.actionList_habitual.selectedActionIds,
      },
    }),
    setActionTopCurrentTab: (state, action: PayloadAction<ActionListType>) => ({
      ...state,
      actionTop: {
        currentTab: action.payload,
      },
    }),
    setDoneActionReview: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isDoneActionReview: action.payload,
    }),
    initTopPageState: (state, action: PayloadAction<undefined>) => ({
      ...state,
      isDoneTopPage: true, // トップページ表示済み
      isDoneActionReview: initialState.isDoneActionReview, // 振り返り完了からの遷移フラグ初期化
    }),
  },
});

export default greenscoreSlice.reducer;

export const {
  setActionListCurrentTab,
  setActionTopCurrentTab,
  toggleSelectedActionId,
  resetSelectedActionIds,
  setDoneActionReview,
  initTopPageState,
} = greenscoreSlice.actions;
