// Or from '@reduxjs/toolkit/query/react'
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { InitialScoreResults } from "./types";

export const CMS_TAG_TYPE = {
  GREENSCORE_ACTIONS: "InitialScoreResult",
} as const;

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env["NEXT_PUBLIC_CMS_BASE_URL"] as string,
    prepareHeaders: (headers) => {
      headers.set(
        process.env["NEXT_PUBLIC_CMS_API_KEY_HEADER"] as string,
        process.env["NEXT_PUBLIC_CMS_API_KEY_VALUE"] as string
      );
      return headers;
    },
  }),
  reducerPath: "cmsInitialScoreResult",
  tagTypes: [CMS_TAG_TYPE.GREENSCORE_ACTIONS],
  endpoints: (builder) => ({
    getInitialScoreResult: builder.query<InitialScoreResults, void>({
      query: () => "initial-score-result",
    }),
  }),
});
export default api;
export const { useGetInitialScoreResultQuery } = api;
