import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type OnboardingState = {
  isClickHamburger: boolean;
};

const initialState: OnboardingState = {
  isClickHamburger: false,
};

/**
 * グローバルナビメニューのカレント表示を管理する
 */
const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    setOnboardingPage: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isClickHamburger: action.payload,
    }),
  },
});

export default onboardingSlice.reducer;

export const { setOnboardingPage } = onboardingSlice.actions;
