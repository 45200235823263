import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type ReferralCodeState = {
  invParam?: string;
};

const initialState: ReferralCodeState = {};

/**
 * グローバルナビメニューのカレント表示を管理する
 */
const referralCodeSlice = createSlice({
  name: "referralCode",
  initialState,
  reducers: {
    setInvParam: (state, action: PayloadAction<string>) => ({
      ...state,
      invParam: action.payload,
    }),
  },
});

export default referralCodeSlice.reducer;

export const { setInvParam } = referralCodeSlice.actions;
