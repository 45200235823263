/* eslint-disable @next/next/no-img-element */
import { css } from "@emotion/css";
import { useRouter } from "next/router";
import { FC, useCallback } from "react";
import { clearError, ErrorState } from "../../../services/error/slice";
import { useAppDispatch } from "../../../services/store";
import { sourceServices } from "../../../utils/error";
import { mediaQuery } from "../../../utils/ui";
import { SettingSubButton } from "../../atoms/SettingSubButton";
import { headerHeight } from "../../organisms/Header";
import { ActionAddButton } from "../../atoms/button/ActionAddButton";

type Props = ErrorState;

const prefix = "GK";
const envCode = "A";

export const Error: FC<Props> = ({
  sourceService = sourceServices.none,
  errorCode = "001",
  userMessage = "処理中にエラーが発生しました。\nしばらくしてから再度アクセスしてください。",
  type = "Error",
}) => {
  const code = `${prefix}-${envCode}${sourceService.code}-${
    type === "Error" ? "E" : "W"
  }${errorCode}`;

  return (
    <main className={backgroundStyle}>
      <article className={boxStyle}>
        <div className={imgBoxStyle}>
          <img
            src={`/error/errorHeader.png?${process.env.NEXT_PUBLIC_IMAGE_PARAMETERS}`}
            height={286}
            width={593}
            alt="エラーが発生しました"
            className={imgStyle}
          />
        </div>
        <h1 className={titleStyle}>エラーが発生しました</h1>
        <p className={messageStyle}>{userMessage}</p>
        <p className={codeStyle}>{`エラーコード：${code}`}</p>
        <div className={buttonAreaStyle}>
          <ActionAddButton
            title="ホームに戻る"
            onclick={() => {
              window.location.href = "/";
            }}
            isDisabled={false}
          />
        </div>
      </article>
    </main>
  );
};

const backgroundStyle = css`
  width: 100%;
  height: 100vh;
  background-image: url(${`/img/templates/home/background.png?${process.env.NEXT_PUBLIC_IMAGE_PARAMETERS}`});
  background-repeat: no-repeat;
  background-size: cover;
  ${mediaQuery.portrait} {
    width: 100%;
    height: 100vh;
    background-image: url(${`/img/templates/home/background.png?${process.env.NEXT_PUBLIC_IMAGE_PARAMETERS}`});
    background-repeat: no-repeat;
  }
  ${mediaQuery.landscape} {
    width: 100%;
    height: 100vh;
    background-image: url(${`/img/templates/home/background.png?${process.env.NEXT_PUBLIC_IMAGE_PARAMETERS}`});
    background-repeat: no-repeat;
  }
`;
const boxStyle = css`
  //width: 375px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  ${mediaQuery.portrait} {
    //width: 400px;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
  }
  ${mediaQuery.landscape} {
    //width: 560px;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
  }
`;

const imgStyle = css`
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  object-fit: cover;
  object-position: top;
`;

const imgBoxStyle = css`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
`;

const titleStyle = css`
  color: var(--text-01, #fff);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  width: 100%;
  margin-bottom: 16px;
  ${mediaQuery.landscape} {
    font-size: 20px;
    font-weight: 600;
    width: 100%;
    text-align: center;
    margin-bottom: 16px;
  }
`;

const messageStyle = css`
  color: var(--text-01, #fff);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  white-space: pre-wrap;
  margin-bottom: 16px;
  ${mediaQuery.landscape} {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    white-space: pre-wrap;
    margin-bottom: 16px;
  }
`;

const codeStyle = css`
  color: var(--text-01, #fff);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.42px;
  margin-bottom: 24px;
  ${mediaQuery.landscape} {
    font-size: 14px;
    font-weight: 300;
    text-align: center;
  }
`;

const buttonAreaStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 133px;
  ${mediaQuery.landscape} {
    /* position: absolute;
    bottom: 0;
    left: calc((560px - 283px) / 2); */
    margin-bottom: 133px;
  }
`;
