import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserMetadata } from "../../utils/hooks/useGreenActionAuth0";

export type Auth0State = {
  accessToken?: string;
  registedUserMetadata?: UserMetadata; // ユーザー情報登録画面で登録された情報
  isRequiredCreatingAccount: boolean;
  isUpdateUser: boolean;
};

const initialState: Auth0State = {
  accessToken: undefined,
  registedUserMetadata: undefined,
  isRequiredCreatingAccount: false,
  isUpdateUser: false,
};

const auth0Slice = createSlice({
  name: "auth0",
  initialState,
  reducers: {
    setAccessToken: (state, action: PayloadAction<string>) => ({
      ...state,
      accessToken: action.payload,
    }),
    setUserMetadata: (state, action: PayloadAction<UserMetadata>) => ({
      ...state,
      registedUserMetadata: action.payload,
    }),
    requireCreatingAccount: (state, action: PayloadAction<void>) => ({
      ...state,
      isRequiredCreatingAccount: true,
    }),
    setUpdateUser: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isUpdateUser: action.payload,
    }),
  },
});

export default auth0Slice.reducer;
export const {
  setAccessToken,
  setUserMetadata,
  requireCreatingAccount,
  setUpdateUser,
} = auth0Slice.actions;
