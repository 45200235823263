import { createApi } from "@reduxjs/toolkit/query/react";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import { GraphQLClient } from "graphql-request";

export const client = new GraphQLClient(
  process.env["NEXT_PUBLIC_HASURA_BASE_URL"] as string
);

export const GRAPHQL_TAG_TYPE = {
  VIEW_NOW: "ViewNow",
  USERS: "Users",
  USER_GREENSCORE_ACTIONS: "GetUserGreenscoreActions",
  USER_GREENSCORE_ACTIONS_BY_STATUS: "GetUserGreenscoreActionsByStatus",
  USER_GREENSCORE_ACTIONS_BY_PK: "GetUserGreenscoreActionsByPk",
  USER_GREENSCORE_REVIEW_LAST2: "GetUserGreenscoreReviewLast2",
  USER_ACTION_COUNT_BY_STATUS: "GetUserActionCountByStatus",
  USER_GREENSCORE_LATEST: "GetUserGreenscoreLatest",
  MY_DATA: "GetMyData",
  INIT_HEARING: "DeleteInitHearing",
  GREENSCORE_DETAIL: "GetGreenscoreDetail",
  GREENSCORE_REFERRAL_CODE: "GetGreenscoreReferralCode",
  GREENSCORE_CO2_EMISSION: "GetGreenscoreCo2Emission",
  GREENSCORE_REFERRAL_CODE_BY_USER_ID: "GetGreenscoreReferralCodeByUserId",
  LAST_USER_ELECTRICITY_DATA: "GetLastUserElectricityData",
  MISSION_ACHIEVEMENT: "GetMissionAchievement",
  DONE_MISSION_ACHIEVEMENT: "GetDoneMissionAchievement",
  CHALLENGE_CODE_MASTER: "GetChallengeCodeMaster",
} as const;

export const api = createApi({
  baseQuery: graphqlRequestBaseQuery({ client }),
  reducerPath: "graphql",
  endpoints: () => ({}),
  tagTypes: [
    GRAPHQL_TAG_TYPE.VIEW_NOW,
    GRAPHQL_TAG_TYPE.USERS,
    GRAPHQL_TAG_TYPE.USER_GREENSCORE_ACTIONS,
    GRAPHQL_TAG_TYPE.USER_GREENSCORE_ACTIONS_BY_STATUS,
    GRAPHQL_TAG_TYPE.USER_GREENSCORE_ACTIONS_BY_PK,
    GRAPHQL_TAG_TYPE.USER_GREENSCORE_REVIEW_LAST2,
    GRAPHQL_TAG_TYPE.USER_ACTION_COUNT_BY_STATUS,
    GRAPHQL_TAG_TYPE.USER_GREENSCORE_LATEST,
    GRAPHQL_TAG_TYPE.MY_DATA,
    GRAPHQL_TAG_TYPE.INIT_HEARING,
    GRAPHQL_TAG_TYPE.GREENSCORE_DETAIL,
    GRAPHQL_TAG_TYPE.GREENSCORE_REFERRAL_CODE,
    GRAPHQL_TAG_TYPE.GREENSCORE_CO2_EMISSION,
    GRAPHQL_TAG_TYPE.GREENSCORE_REFERRAL_CODE_BY_USER_ID,
    GRAPHQL_TAG_TYPE.LAST_USER_ELECTRICITY_DATA,
    GRAPHQL_TAG_TYPE.MISSION_ACHIEVEMENT,
    GRAPHQL_TAG_TYPE.DONE_MISSION_ACHIEVEMENT,
    GRAPHQL_TAG_TYPE.CHALLENGE_CODE_MASTER,
  ],
});